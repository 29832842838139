import React, {useState, useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {firebase, auth, firestore} from '../utils/firebase.js';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebaseApp from 'firebase/app';
// import "firebase/auth";
import Loader from '../components/WidgetComponents/Loader'
import {navigate} from 'gatsby'
import signInButton from "../images/signin.png"
import SEO from "../components/seo.js"
import Layout from "../components/layout"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// const amplitude = typeof window !== `undefined`
//   ? require("amplitude-js")
//   : null

const useStyles = makeStyles(theme => ({
  signInButton: {
    width: '200px',
    padding: '0px',
    margin: '0px',
    filter: 'drop-shadow(0px 3px 8px rgba(0,0,0,0.35))',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    outline: 'none',
    '&:hover': {
      filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.2)) brightness(90%)',
      cursor: 'pointer'
    }
  }
}));

// if (amplitude) {
//   amplitude
//     .getInstance()
//     .init("b4712d7cb2ceb55f943a3685fcb61374");
//   amplitude
//     .getInstance()
//     .logEvent('invitation page view');
// }

if (typeof window !== `undefined`) {
  // getFirebase()
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder', 'Lobster:lighter,normal,bold,bolder']
    }
  });
}

// const auth = firebase.auth;

function SignIn({
  location
}, props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [innerHeight, setInnerHeight] = useState(null)
  const [keysLoaded, setKeysLoaded] = useState(false)

  // const [activeUser, setActiveUser] = useState(null)
  // const [isSignedIn, setIsSignedIn] = useState(null)

  // const firebase = getFirebase();

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/onboard/welcome',
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
  };

  const classes = useStyles();

  const parsedQuery = location
    .search
    .slice(1)

  function signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();

    // const state = {
    //   'redirected': true
    // }
    // const title = ''
    // const url = '/authenticating'
    // if (typeof window !== `undefined`) {
    //   window
    //     .history
    //     .pushState(state, title, url)
    // }

    return auth.signInWithPopup(provider);
  }

  async function googleSignIn() {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log('Error')
      // this.setState({error: error.message});
    }
  }

  function capitalizeFirstLetter(string) {
    return string
      .charAt(0)
      .toUpperCase() + string.slice(1);
  }

  useEffect(() => {

    const db = firestore

    let attemptedKey = parsedQuery
    let betaKeysList = []

    let keys = db
      .collection("admin")
      .doc('keys')

    keys
      .get()
      .then(function(doc) {
        if (doc.exists) {
          betaKeysList = doc.data()['beta']
          // console.log(betaKeysList.includes(attemptedKey))
          // console.log(betaKeysList)
          if (attemptedKey.length < 1) {
            navigate("/")
          } else if (!betaKeysList.includes(attemptedKey)) {
            navigate("/usedkey")
          }
          setKeysLoaded(true)
        } else {
          console.log('Nope')
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });

    auth.onAuthStateChanged((user) => {
      console.log('ss')
      if (user && isLoaded) {
        console.log('braps')
        let docRef = db
          .collection("users")
          .doc(user.uid);

        docRef
          .get()
          .then(function(doc) {
            console.log(doc.data())
            console.log(attemptedKey)
            if (doc.exists) {
              if (doc.data()['betaAccess'] == true) {
                navigate('/onboard/welcome')
              } else if (betaKeysList.includes(attemptedKey)) {
              console.log('success')
              // doc.data() will be undefined in this case
              console.log(user)

              // Create new user
              const db = firestore

              let userFirstName = user
                .displayName
                .substring(0, user.displayName.indexOf(" "))
              userFirstName = capitalizeFirstLetter(userFirstName)

              let userLastName = user
                .displayName
                .substring(user.displayName.indexOf(" "))
              userLastName = capitalizeFirstLetter(userLastName)

              db
                .collection("users")
                .doc(user.uid)
                .update({
                  uid: user.uid,
                  // email: user.email,
                  // first: userFirstName,
                  // last: userLastName,
                  // photoURL: user.photoURL,
                  unlockDate: Date(),
                  brands: [],
                  betaKey: parsedQuery,
                  betaAccess: true
                });

              keys.update({
                beta: firebase
                  .firestore
                  .FieldValue
                  .arrayRemove(attemptedKey)
              });

              window
                .analytics
                .track("Unlocked Beta Access", {
                  // userId: user.uid,
                  // "type": "organic",
                  "first_name": userFirstName,
                  "last_name": userLastName,
                  "email": user.email,
                  // "phone": "410-555-9412",
                  // "username": "pgibbons",
                  // "title": "Mr",
                });
              window
                .analytics
                .identify(user.uid, {
                  // name: user.displayName,
                  // email: user.email,
                  beta_access: true
                });
              navigate('/onboard/welcome')
            } else {
              console.log('Invalid invite code')
              navigate('/waitlist')
            }
          }})
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
        // console.log(user)
        // setActiveUser(user);
      } else {
        // localStorage.setItem("onSignIn", "true");
        console.log('No active user')
        if (typeof window !== `undefined`) {
          setInnerHeight(window.innerHeight)
        }
        if (keysLoaded) {
          setIsLoaded(true)
        }
        // navigate('/signin')
      }
    })
  })

  // useEffect(() => {
  //   firebase
  //     .auth()
  //     .onAuthStateChanged((user) => {
  //        setLoaded(true);
  //       if (user) {
  //         console.log('Yeet')
  //       } else {
  //          localStorage.setItem("onSignIn", "true");
  //         console.log('Nope')
  //       }
  //     })
  // })

  // const uiConfig = {
  //    Display Google as auth provider.
  //   signInFlow: 'redirect',
  //    signInSuccessUrl: '/app/waitlist',
  //   signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  //   callbacks: {
  //      uiShown: function() {
  //         The widget is rendered.
  //         Hide the loader.
  //        alert('yeet')
  //      }
  //       Avoid redirects after sign-in.
  //     signInSuccessWithAuthResult: () => false
  //   }
  // };

  // useEffect(() => {
  //   if (!isLoaded) {
  //     firebase
  //       .auth()
  //       .signOut();
  //     if (typeof window !== `undefined`) {
  //        setInnerHeight(window.innerHeight)
  //       setIsLoaded(true)
  //     }
  //   }
  // }, [isLoaded]);

  // let innerHeight = typeof window !== `undefined` ? window.innerHeight : '50vh'

  const page = (<Grow in="in">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        margin: '0px',
        height: innerHeight,
        // width: '100vw',
        padding: '0px 5vw'
      }}>
      <SEO title="Unlocking"/>
      <Typography style={{
          fontFamily: 'Lobster',
          fontWeight: 'bold',
          fontSize: '35px',
          paddingBottom: '30px',
          textAlign: 'center',
          lineHeight: 1.2
        }}>QuickStyle</Typography>
      <Typography style={{
          fontFamily: 'Work Sans',
          fontWeight: 'bold',
          fontSize: '25px',
          paddingBottom: '10px',
          textAlign: 'center',
          lineHeight: 1.2
        }}>{"Congrats, you've been invited to join our private beta! "}
        <span role="img" aria-label="celebrate">🎉</span>
      </Typography>
      <Typography style={{
          fontFamily: 'Work Sans',
          color: '#8c8c8c',
          fontWeight: 'normal',
          fontSize: '22px',
          paddingBottom: '30px',
          textAlign: 'center',
          lineHeight: 1.2
        }}>Sign in with Google to get early access.</Typography>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    </Grid>
  </Grow>)

  return (<Layout>
<Loader/></Layout>)

  // return (<div>
  //   <h1>My App</h1>
  //   <p>Welcome {
  //       firebase
  //         .auth()
  //         .currentUser
  //         .displayName
  //     }! You are now signed-in!</p>
  //   <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
  // </div>)
  // return <Loader/>
}

export default SignIn

// <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
